export default [
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/cloudcart/orders/OrdersList'),
    },
    {
        path: '/orders/:id',
        name: 'orders.view',
        component: () => import('@/views/cloudcart/orders/OrderView'),
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('@/views/cloudcart/products/ProductsList'),
    },
    {
        path: '/products/create',
        name: 'products.create',
        component: () => import('@/views/cloudcart/products/ProductForm'),
    },
    {
        path: '/products/characteristics',
        name: 'products.characteristics',
        component: () => import('@/views/cloudcart/products/Characteristics/CharacteristicsList'),
    },
    {
        path: '/products/:id',
        name: 'products.edit',
        component: () => import('@/views/cloudcart/products/ProductForm'),
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('@/views/cloudcart/categories/CategoriesList'),
    },
    {
        path: '/categories/create',
        name: 'categories.create',
        component: () => import('@/views/cloudcart/categories/CategoryForm'),
    },
    {
        path: '/categories/:id',
        name: 'categories.edit',
        component: () => import('@/views/cloudcart/categories/CategoryForm'),
    },
]

