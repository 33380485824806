import axios from '@axios'

export default {
    namespaced: true,
    state: {
        filters: []
    },
    mutations: {
        SET_FILTERS: (state, data) => {
            state.filters = data;
        },
        ADD_FILTER: (state, data) => {
            state.filters.push(data);
        },
        DELETE_FILTER: (state, filter_id) => {
            let index = window._.findIndex(state.filters, {id: filter_id})
            if (index > -1) {
                state.filters.splice(index, 1)
            }
        }
    },
    actions: {
        fetchFilters(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_BACKEND_URL + '/api/filters', payload).then(response => {
                    ctx.commit('SET_FILTERS', response.data.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        },
        saveFilter(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/api/filters/' + window._.get(payload, 'id', ''), payload)
                    .then(response => {
                        ctx.commit('ADD_FILTER', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteFilter(ctx, filter_id) {
            return new Promise((resolve, reject) => {
                axios.delete(process.env.VUE_APP_BASE_BACKEND_URL + '/api/filters/' + filter_id)
                    .then(response => {
                        ctx.commit('DELETE_FILTER', filter_id)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}