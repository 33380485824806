import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import orders from './cloudcart/orders/index'
import products from './cloudcart/products/index'
import countries from './cloudcart/support/countries'
import shipping from './cloudcart/orders/shipping'
import billing from './cloudcart/orders/billing'
import filters from './cloudcart/filters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    orders,
    products,
    countries,
    shipping,
    billing,
    filters,
    //theme related below
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
