import axios from '@axios'

export default {
    namespaced: true,
    state: {
        countries: [],
    },
    mutations: {
        SET_COUNTRIES: (state, data) => {
            state.countries = data;
        }
    },
    actions: {
        fetch(ctx) {
            if (this.state.countries.length) {
                return new Promise(resolve => {
                    resolve(this.state.countries);
                });
            }
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_BACKEND_URL + '/api/support/countries').then(response => {
                    ctx.commit('SET_COUNTRIES', Object.values(response.data));
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        }
    }
}