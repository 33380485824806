import axios from '@axios'
export default {
    namespaced: true,
    state: {
        orders: {},
        order: {
            customer: {},
            shipping: {},
            products: []
        }
    },
    getters: {
        paginatedOrders: state => {
            return state.orders;
        },
        order: state => {
            return state.order;
        }
    },
    mutations: {
        SET_ORDERS: (state, data) => {
            state.orders = data;
        },
        SET_ORDER: (state, data) => {
            state.order = data;
        },
        ADD_PRODUCT: (state, product) => {
            let existingIndex = window._.findIndex(state.order.products, item => item.id === product.id);
            if (existingIndex > -1) {
                state.order.products[existingIndex] = product;
            } else {
                state.order.products.push(product)
            }
        },
        REMOVE_PRODUCT: (state, productId) => {
            let productIndex = window._.findIndex(state.order.products, product => product.id === productId);
            state.order.products.splice(productIndex, 1)
        },
        UPDATE_PRODUCT: (state, product) => {
            window._.map(state.order.products, existing => {
                if (existing.id === product.id) {
                    return product
                }
                return existing
            })
        },
        UPDATE_PRODUCT_VARIANT: (state, variant) => {
            window._.map(state.order.products, product => {
                if (product.variant.id === variant.id) {
                    product.variant = variant;
                }
            });
        },
        REMOVE_PRODUCT_DISCOUNT: (state, product_id) => {
            let product = window._.find(state.order.products, {id: product_id});
            product.variant.discounts = [];
        },
        UPDATE_ORDER_ADDRESS: (state, address) => {
            state.order.address = address;
        },
        UPDATE_ORDER_BILLING: (state, address) => {
            state.order.billing = address;
        }
    },
    actions: {
        fetchOrders(ctx, payload) {
            payload._method = 'get'
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders', payload).then(response => {
                    ctx.commit('SET_ORDERS', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        },
        fetchOrder(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/' + payload.id, payload).then(response => {
                    ctx.commit('SET_ORDER', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        },
        addProductToOrder(ctx, payload) {
            return new Promise((resolve, reject) => {
                if (!window._.get(ctx.state.order, 'id')) {
                    reject('No order id');
                }
                axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/' + ctx.state.order.id + '/products', {
                    product_variant_id: payload.variant_id,
                    quantity: payload.quantity,
                    price: payload.price
                }).then(response => {
                    ctx.commit('ADD_PRODUCT', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        removeProductFromOrder(ctx, productId) {
            return new Promise((resolve, reject) => {
                if (!window._.get(ctx.state.order, 'id')) {
                    reject('No order id');
                }
                axios.delete(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/' + ctx.state.order.id + '/products/' + productId)
                    .then(response => {
                        ctx.commit('REMOVE_PRODUCT', productId)
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateProduct(ctx, payload) {
            return new Promise((resolve, reject) => {
                if (!window._.get(ctx.state.order, 'id')) {
                    reject('No order id');
                }
                axios.patch(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/' + ctx.state.order.id + '/products/' + payload.product_id, {
                    price: payload.price,
                    quantity: payload.quantity
                }).then(response => {
                    ctx.commit('UPDATE_PRODUCT', response.data)
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateProductDiscount(ctx, payload) {
            return new Promise((resolve, reject) => {
                if (!window._.get(ctx.state.order, 'id')) {
                    reject('No order id');
                }
                axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/' + ctx.state.order.id + '/products/' + payload.product_id + '/discount', {
                    discount_type: payload.discount_type,
                    discount_value: payload.discount_value
                }).then(response => {
                    ctx.commit('UPDATE_PRODUCT_VARIANT', response.data)
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        removeProductDiscount(ctx, payload) {
            return new Promise((resolve, reject) => {
                    if (!window._.get(ctx.state.order, 'id')) {
                        reject('No order id');
                    }
                    axios.delete(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/' + ctx.state.order.id + '/products/' + payload.product_id + '/discount')
                        .then(response => {
                            ctx.commit('REMOVE_PRODUCT_DISCOUNT', payload.product_id);
                            resolve(response);
                        })
                        .catch(error => {
                            reject(error);
                        })
                }
            );
        },
        updateOrderAddress(ctx, address) {
            ctx.commit('UPDATE_ORDER_ADDRESS', address)
        },
        updateOrderBilling(ctx, address) {
            ctx.commit('UPDATE_ORDER_BILLING', address)
        }
    }
}