import axios from '@axios'

export default {
    namespaced: true,
    state: {
        billing: {},
    },
    mutations: {
        SET_BILLING: (state, data) => {
            state.billing = data;
        }
    },
    actions: {
        get(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/billing/' + payload.billing_id)
                    .then(response => {
                        ctx.commit('SET_BILLING', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                    });
            })
        },
        store(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/api/orders/billing/' + payload.order_id + '/' + payload.billing.id, payload.billing)
                    .then(response => {
                        ctx.commit('SET_BILLING', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}