import axios from '@axios'

export default {
    namespaced: true,
    state: {
        products: {},
        product: {}
    },
    getters: {
        paginatedProducts: state => {
            return state.products;
        },
        product: state => {
            return state.product;
        }
    },
    mutations: {
        SET_PRODUCTS: (state, data) => {
            state.products = data;
        },
        SET_PRODUCT: (state, data) => {
            state.product = data;
        }
    },
    actions: {
        fetchProducts(ctx, payload) {
            payload._method = 'get'
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_BACKEND_URL + '/api/products', payload).then(response => {
                    ctx.commit('SET_PRODUCTS', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        },
        fetchProduct(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_BACKEND_URL + '/api/products/' + payload.id, payload).then(response => {
                    ctx.commit('SET_PRODUCT', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        }
    }
}