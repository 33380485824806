import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('fa', FontAwesomeIcon)

import {
    faImages,
    faImage,
    faCog,
    faVideo,
} from '@fortawesome/free-solid-svg-icons'

import {
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faImages,
    faImage,
    faCog,
    faVideo,
    faYoutube
)